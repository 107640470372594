.placement {
  @media screen and (min-width: 768px) {
    flex-direction: row;
    min-height: 20rem;
  }

  font-family: "LexendDeca-Regular";
  display: flex;
  flex-direction: column;
  max-height: unset;

  &__question {
    order: 2;

    @media screen and (min-width: 768px) {
      margin-right: 1rem;
      order: unset;
    }

    &--choices {
      width: 100%;
      contain: content;

      button {
        place-content: baseline;
        padding: 1rem;
        border-radius: 0.5rem;
        font-family: "LexendDeca-Regular" !important;
      }
    }
  }

  &__option {
    height: 3rem;
  }
}

.englease-rtl {
  .placement {
    direction: ltr;
    text-align: left;

    &__question {
      // margin-right: 0;
      // margin-left: 5rem;
      &--choices {
        button {
          font-family: "Cairo-Regular" !important;
        }
      }
    }
  }
}

//override styles material ui for this component
// .css-d9c359-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
.MuiButtonBase-root .MuiToggleButton-root .Mui-selected {
  background-color: #9d9d9d !important;
  color: #000 !important;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

.bookmark-btn {
  text-align: end;
  float: right;
}

.attend-assessment {
  &__question-title {
    color: #424b5a;
    font-size: 21.0478px;
    line-height: 33px;
  }

  &__placement-title {
    text-align: center;
  }

  &__exit {
    color: #ef4431;
  }

  &__questions-wrapper {
    display: flex;
    align-items: center;
  }

  &__questions-title {
    text-align: start;
  }

  &__questions-number {
    width: 53px;
    padding: 15px 3px;
    border-radius: 50%;
    background-color: #e9d700;
    font-size: 14px;
    margin-left: 0.7rem;
    margin-right: 0.7rem;
  }

  &__option,
  .list-group-item {
    text-align: start;
    border: 0;
    border-radius: 10px;

    &:hover,
    &:active,
    &:focus {
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0.04) !important;
    }
  }

  .btn-check:active + .btn-primary,
  .btn-primary {
    &:hover,
    &:active,
    &:focus {
      box-shadow: none !important;
      color: #000 !important;
    }
  }

  &__prev-btn {
    cursor: pointer;
    text-decoration: underline;
  }

  &__next-holder {
    text-align: end;

    button {
      border-radius: 50%;
      width: 51px;
    }
  }

  &__next-btn {
    background-color: var(--color-main) !important;
  }

  &__modal-title {
    color: #000000;
    font-weight: 900;
  }

  &__modal-sub-title {
    color: #ef4431;
    font-weight: 400;
    max-width: 260px;
  }

  .progress-bar {
    // background: #424b5a;
    background: #4c0086;
    border-radius: 0.25rem;
  }
}

.englease-rtl {
  .attend-assessment {
    direction: ltr !important;
    text-align: left !important;

    &__prev-btn {
      text-align: left;
    }

    &__questions-wrapper {
      direction: ltr;
    }

    // 		&__next-btn {
    // 			img {
    // 				transform: rotateY(180deg);
    // 			}
    // 		}
  }
}
