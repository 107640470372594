.true-false {
	&__resetBtn {
		border: none;
		display: block;
		margin: 1rem 0;
		background: none;
	}
	label {
		width: 3.75rem;
	}
}

input[type="radio"] {
	display: none;
}
.success {
	animation: successRotate 0.6s ease forwards;
	@keyframes successRotate {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(-360deg);
			fill: #00b01a;
		}
	}
}
.fail {
	animation: failRotate 0.6s ease forwards;
	@keyframes failRotate {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(-360deg);
			fill: #e33624;
		}
	}
}
