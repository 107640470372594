@import "./_variables";
@import "./_fonts";
@import "./_icons";
@import "./_images";
@import "./__colors";
@import "./_utilities";

// START === General Customization
body {
	// max-width: 1920px;
	margin: auto !important;
}
::-webkit-scrollbar {
	width: 8px !important;
}

::-webkit-scrollbar-track {
	background-color: var(--color-Lavender) !important;
	border-radius: 35px !important;
}

::-webkit-scrollbar-thumb {
	background-color: var(--color-main) !important;
	border-radius: 35px !important;
}

