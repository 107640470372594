.commitment {
	&__title {
		color: #4c0086;
		text-align: center;
		font-size: 40px;
		font-weight: 700;
		text-transform: capitalize;
	}

	&__sub-title {
		color: #000;
		font-size: 28px;
		font-weight: 500;
	}

	&__question {
		color: #4c0086;
		font-size: 24px;
		font-weight: 500;
	}

	&__img-wrapper {
		margin-bottom: 2rem;
	}

	&__question-wrapper {
		margin-bottom: 3rem;
	}

	&__message {
		&--display {
			display: flex;
			align-items: center;
			height: 100%;
		}
	}
}

.englease-rtl {
	.commitment {
		direction: ltr;
		text-align: left;
	}
}
