.iET-Close {
	background: transparent url("../../public/assets/icons/closeBtn.png")
		center/2em auto no-repeat !important;
}
.iET-celebration {
	background-image: url("../../public/assets/icons/celebration.svg");
}
//  START === Icon Size

.iET-s16 {
	width: 16px;
	height: 16px;
}
.iET-s21 {
	width: 21px;
	height: 21px;
}
.iET-s24 {
	width: 24px;
	height: 24px;
}
.iET-m80 {
	width: 80px;
	height: 80px;
}
// END === Icon Size
