.fill-gaps-selection {
	&__title {
		font-weight: 600;
	}

	&__resetBtn {
		border: none;
		display: block;
		margin: 1rem 0;
		background: none;
	}

	&__question {
		order: 2;
		@media screen and (min-width: 768px) {
			order: unset;
		}
		&--choices {
			width: 100%;
			contain: content;
			button {
				place-content: baseline;
				padding: 1rem;
				border-radius: 0.5rem;
				font-family: "LexendDeca-Regular" !important;
			}
		}
	}
}

.englease-rtl {
	.fill-gaps-selection {
		direction: ltr;
		text-align: left;
		&__question {
			&--choices {
				button {
					font-family: "Cairo-Regular" !important;
				}
			}
		}
	}
}

.MuiButtonBase-root.MuiToggleButton-root {
	padding: 0.6rem 0.9rem !important;
	border-radius: 0.5rem !important;
	margin: 0.5rem !important;
	width: fit-content;
	border: 1px solid rgba(0, 0, 0, 0.12) !important;
	text-transform: none !important;
	color: #4d4d4d;
	font-family: "LexendDeca-regular" !important;
	font-weight: 400;

	&:first-child {
		margin: 0.5rem 0.5rem 0.5rem 0 !important;
	}
}

.MuiButtonBase-root.MuiToggleButton-root.Mui-selected {
	background-color: #1ab831 !important;
	color: #000 !important;
}

.MuiToggleButtonGroup-root {
	display: contents !important;
}
