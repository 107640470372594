// .css-1t1j96h-MuiPaper-root-MuiDialog-paper{
.MuiPaper-root.MuiDialog-paper {
	border-radius: 10px;
	padding: 32px;
	// width: 400px;
}

// .css-bdhsul-MuiTypography-root-MuiDialogTitle-root{
.MuiTypography-root.MuiDialogTitle-root {
	padding: 0 !important;
}

.self-study__popup {
	&--border {
		border: 1px solid #bebebe;
		border-radius: 10px;
	}
}

.certificate {
	&__btn{
		// color: #e9d700 !important;
		color: #000 !important;
	}

	.btn:focus,
	.btn:active {
		outline: none;
	}
}
