.englease {
	&__bg {
		&--cover {
			display: inline-block;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}
		&--contain {
			display: inline-block;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		}
	}
	// END === Images Customization

	&__radius {
		&--f {
			&-4 {
				border-radius: var(--size-4) !important;
			}

			&-8 {
				border-radius: var(--size-8) !important;
			}
			&-16 {
				border-radius: var(--size-16);
			}

			&-32 {
				border-radius: var(--size-32) !important;
			}
		}
	}

	// END===Border Radius

	&__border {
		&--gray {
			border: 1px solid #f0f0f0;
		}
		&--main {
			border: 1px solid var(--color-main) !important;
		}
		&--secondary {
			border: 1px solid var(--color-secondary) !important;
			&-20 {
				border: 1px solid #dae7fd;
			}
		}
		&--black {
			border: 1px solid var(--color-black) !important; 
		}
		&--success {
			border: 1px solid var(--color-success) !important;
		}
		&--mute {
			border: 1px solid var(--color-mute) !important;
		}
		&--muteTxt {
			border: 1px solid var(--color-muteTxt) !important;
		}
		&--muteBorder {
			border: 1px solid var(--color-muteBorder) !important;
		}
	}
	&--click {
		cursor: pointer;
	}
	&--unClick {
		cursor: not-allowed;
		opacity: 0.4;
	}
	// END===Border
}
// START === Z-Index
.z-index-1 {
	z-index: 1;
}
// END === Z-Index