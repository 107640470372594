.checkbox-wrapper-30 {
	user-select: none;
	background-color: #f8f8f8;
	&__image {
		aspect-ratio: 3 / 4;
		// height: 10rem;
		// width: 100%;
	}
	// @media screen and (min-width: 992px) {
	// 	&__image {
	// 		height: 20rem;
	// 		width: 100%;
	// 	}
	// }

	&__resetBtn {
		border: none;
		display: block;
		margin: 1rem 0;
		background: none;
	}
}
.checkbox-wrapper-30 .checkbox {
	--bg: #fff;
	--brdr: #d1d6ee;
	--brdr-actv: #4c0b86;
	--brdr-hovr: #4c0b86;
	--dur: calc((var(--size, 1) / 2) * 0.6s);
	display: inline-block;
	position: relative;
	&__image {
		aspect-ratio: 3 / 4;
		// height: 10rem;
		// 	width: 100%;
	}
	// @media screen and (min-width: 992px) {
	// 	&__image {
	// 		height: 20rem;
	// 		width: 100%;
	// 	}
	// }
}
.checkbox-wrapper-30 .checkbox:after {
	content: "";
	width: 24px;
	padding-top: 100%;
	display: block;
}
.checkbox-wrapper-30 .checkbox > * {
	position: absolute;
}
.checkbox-wrapper-30 .checkbox input {
	-webkit-appearance: none;
	-moz-appearance: none;
	-webkit-tap-highlight-color: transparent;
	cursor: pointer;
	background-color: var(--bg);
	border-radius: calc(var(--size, 1) * 4px);
	border: 1px solid;
	color: var(--newBrdrClr, var(--brdr));
	outline: none;
	margin: 0;
	padding: 0;
	transition: all calc(var(--dur) / 3) linear;
}
.checkbox-wrapper-30 .checkbox input:hover,
.checkbox-wrapper-30 .checkbox input:checked {
	--newBrdr: calc(var(--size, 1) * 2);
}
.checkbox-wrapper-30 .checkbox input:hover {
	--newBrdrClr: var(--brdr-hovr);
}
.checkbox-wrapper-30 .checkbox input:checked {
	--newBrdrClr: var(--brdr-actv);
	transition-delay: calc(var(--dur) / 1.3);
}
.checkbox-wrapper-30 .checkbox input:checked + svg {
	--dashArray: 16 93;
	--dashOffset: 109;
}
.checkbox-wrapper-30 .checkbox svg {
	fill: none;
	left: 0;
	pointer-events: none;
	stroke-dasharray: var(--dashArray, 93);
	stroke-dashoffset: var(--dashOffset, 94);
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-width: 1.5px;
	top: 0;
	transition: stroke-dasharray var(--dur), stroke-dashoffset var(--dur);
}
.checkbox-wrapper-30 .checkbox svg,
.checkbox-wrapper-30 .checkbox input {
	display: block;
	height: 24px;
	width: 24px;
}
label img {
	height: 100px;
	width: 100px;
	// aspect-ratio: 3 / 4;

	transition-duration: 0.2s;
}

:checked + label img {
	transform: scale(0.9);
	/* box-shadow: 0 0 5px #333; */
	z-index: -1;
}
