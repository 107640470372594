.modal-content{
  border: 0px !important;
}
.modal-footer {
  border-top: 0 !important;
  justify-content: space-around !important;
  flex-direction: row;
  flex-wrap: nowrap !important;
}

.modal-btn {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  vertical-align: middle;
  text-align: center;
}
.modal-exit-btn {
  padding: 16px 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  vertical-align: middle;
  text-align: center;
  background-color: transparent;
  border: 1px solid var(--color-wrong);
  color: var(--color-wrong);
}

.confirm-btn {
  padding: 16px 0.75rem;
  color: #fff;
  background-color: #4c0b86 !important;
  border-color: #4c0b86 !important;
  box-shadow: none;
}

.exitBtn {
  background-color: transparent;
  color: #ef4431;
  border: 1px solid #ef4431;
}
.btn-close{
  background: transparent url('../../../../public/assets/icons/closeBtn.png') center/2em auto no-repeat  !important;
}
