.btn-radio {
	cursor: pointer;
	background-color: #f8f8f8;
	display: flex;
	align-items: center;
	gap: 16px;
	-webkit-user-select: none;
	user-select: none;

	&__image {
		// height: 10rem;
		// width: 100%;
		aspect-ratio: 3 / 4;
	}

	&__resetBtn {
		border: none;
		display: block;
		margin: 1rem 0;
		background: none;
	}

	&_ &:not(:first-child) {
		margin-left: 20px;
	}
	// @media screen and (min-width: 992px) {
	// 	&__image {
	// 		height: 21rem;
	// 		width: 100%;
	// 	}
	// }
	@media screen and (max-width: 480px) {
		display: block;
		float: none;
		&:not(:first-child) {
			margin-left: 0;
			margin-top: 15px;
		}
	}
	.svg-holder {
		svg {
			width: 24px;
			height: 24px;
			fill: none;
			vertical-align: middle;
			circle {
				width: 24px;
				height: 24px;
				stroke-width: 2;
				stroke: #d0d5dd;
			}
			path {
				width: 24px;
				height: 24px;
				stroke: #4c0b86;
				&.inner {
					width: 24px;
					height: 24px;
					stroke-width: 6;
					stroke-dasharray: 19;
					stroke-dashoffset: 19;
				}
				&.outer {
					width: 24px;
					height: 24px;
					stroke-width: 2;
					stroke-dasharray: 57;
					stroke-dashoffset: 57;
				}
			}
		}
	}

	input {
		display: none;
		&:checked + .svg-holder svg {
			width: 24px;
			height: 24px;
			path {
				transition: all 0.4s ease;
				&.inner {
					stroke-dashoffset: 38;
					transition-delay: 0.3s;
				}
				&.outer {
					stroke-dashoffset: 0;
				}
			}
		}
	}
}
