.fill-gaps {
	font-family: "LexendDeca-Regular" !important;
	select,
	option {
		font-family: "LexendDeca-Regular" !important;
	}

	&__title {
		font-weight: 600;
	}

	&__resetBtn {
		border: none;
		display: block;
		margin: 1rem 0;
		background: none;
	}

	&__question {
		order: 2;
		line-height: 2.4;
		@media screen and (min-width: 768px) {
			order: unset;
			line-height: 2.75;
		}
		&--choices {
			width: 100%;
			contain: content;
			button {
				place-content: baseline;
				padding: 1rem;
				border-radius: 0.5rem;
				font-family: "LexendDeca-Regular" !important;
			}
		}
	}
}

.englease-rtl {
	.fill-gaps {
		font-family: "Cairo-Regular" !important;
		direction: ltr;
		text-align: left;

		select,
		option {
			font-family: "Cairo-Regular" !important;
		}

		&__question {
			&--choices {
				button {
					font-family: "Cairo-Regular" !important;
				}
			}
		}
	}
}
