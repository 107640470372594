.placement {
	&__videoAttachment,
	&__imageAttachment {
		@media screen and (min-width: 768px) {
			//override for the react player package styles
			width: 90% !important;
			max-width: unset !important;
		}
		margin-bottom: 2rem;
		order: 1;
		width: 100% !important;
		min-height: 100% !important;
	}

	&__audioAttachment {
		@media screen and (min-width: 768px) {
			width: 23rem !important;
			height: 13rem !important;
			order: unset;
			margin-left: 21%;
		}
		order: 1;
		width: 100% !important;
		margin: unset;
		margin-bottom: 2rem;
		height: 2rem !important;
	}
}
