:root{

--border-radius: 1px ;
--primary-font-size: 1.6rem ;
--secondary-font-size: 1.4rem ;

// START === GENERAL Sizes

--size-4: 4px ;
--size-8: 8px ;
--size-16: 16px ;
--size-24: 24px ;
--size-32: 32px ;
--size-40: 40px ;
--size-48: 48px ;

// END === GENERAL Sizes

// START === GENERAL Colors
  --color-main: #4c0b86 ;
  --color-sup: #ffe8db ;
  --color-smoke: #f2f2f2 ;
  --color-secondary: #244d95 ;
  --color-black: #212529 ;
  --color-white: #ffffff ;
  --color-light: #f9fafe ;
  --color-mute: #e8e9eb ;
  --color-muteTxt: #747474 ;
  --color-muteBorder: #bebebe ;
  --color-gray: #585858 ;
  --color-success: #35b779 ;
  --color-orange: #ef4431 ;
  --color-error: #fed8d4 ;
  --color-wrong: #ef4431 ;
  --color-inherit: inherit ;
  --color-Lavender: #f1e2ff ;
  --color-nav: #212529 ;
}

// END === GENERAL Colors
